import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TProductblock, TProductblockState } from '../../../../../types';
import { Ratio } from '../../../../dumb/Ratio';
import { Ripple } from '../../../../dumb/Ripple';
import { WrapperWithLoading } from '../../../../dumb/WrapperWithLoading';
import { withState } from '../../../../../utils/withState';
import { SelectProductblockItem } from './SelectProductblockItem';
import { apiProductblocks } from '../../../../../api/apiProductblocks';
import { CelebrationProductblockForm } from '../../../../smart/CelebrationProductblockForm';
import { TSetStateArg } from '../../../../../utils/getItemFromStoreById';


export type TSelectProductblockPagesProps = {
  className?: string,
  celebrationId?: string,
};

export type TSelectProductblockPagesState = {
  pages: TProductblock[],
  productblockId: string,
  productblockData: TProductblockState
  loading: boolean,
  opened: boolean,
};


export const SelectProductblocks = withState<
  TSelectProductblockPagesProps,
  TSelectProductblockPagesState
>((setState, self) => {
  function productblockDataInitial() {
    return {
      id: '',
      text: '',
      link: '',
      icon: '',
      celebrationId: self.props.celebrationId || '',
      isActive: true,
      position: ''
    }
  }

  self.state = {
    pages: [],
    productblockId: '',
    loading: true,
    opened: false,
    productblockData: productblockDataInitial()
  };


  function handleSelect(id: string) {
    const data = self.state.pages.find((v) => v.id === id) || productblockDataInitial();

    setState({
      opened: true,
      productblockId: id,
      productblockData: {
        ...data,
        position: '' + (data.position || 0),
      },
    });
  }


  const {
    localEffect,
    localMemo,
  } = self;

  
  async function handleRemove(params: {
    ids: string[],
  }) {
    setState({
      loading: true,
    });
    await apiProductblocks.remove(params);

    const {
      ids,
    } = params;
    setState({
      loading: false,
      pages: self.state.pages
        .filter((page) => !ids.includes(page.id || '')),
    });
  }

  return (props, state) => {
    const {
      celebrationId,
    } = props;
    const {
      pages,
      productblockData
    } = state;

    localEffect(async () => {
      const response = await apiProductblocks.getList({
        query: {
          celebrationId,
          celebration_id: celebrationId,
        },
      });
      setState({
        pages: response?.data || [],
        loading: false,
      });
    }, [celebrationId]);

    const items = localMemo(() => {
      const items = [...pages];
      items.sort((a, b) => a.position - b.position);
      return items;
    }, [pages]);


    function handleAdd() {
      setState({
        opened: true,
        productblockId: '',
        productblockData: productblockDataInitial(),
      });
    }

    function handleBack() {
      setState({
        opened: false,
        productblockId: '',
        productblockData: productblockDataInitial()
      });
    }

    async function handleSave() {
      const {
        state,
        props,
      } = self;

      const {
        productblockId,
        productblockData,
        pages,
      } = state;


      const params = {
        ...productblockData,
        position: parseInt(productblockData.position as any || '0'),
        celebrationId: props.celebrationId || '',
      };

      if (productblockId) {
        apiProductblocks.change({
          id: productblockId,
          params,
        });

        setState({
          opened: false,
          productblockId: '',
          productblockData: productblockDataInitial(),
          pages: pages.map((v) => {
            return v.id === productblockId ? {
              ...params,
              id: productblockId,
            } : v;
          }),
        });
      } else {
        const response = await apiProductblocks.create({
          params,
        });
        const data = response?.data;

        data && setState({
          opened: false,
          productblockId: '',
          productblockData: productblockDataInitial(),
          pages: [...pages, data],
        });
      }
    }

    function handleChange(productblockData: TSetStateArg<TProductblockState>) {
      const prev = self.state.productblockData

      setState({
        productblockData: {
          ...prev,
          ...(typeof productblockData === 'function' ? productblockData(prev) : productblockData),
        }
      })
    }


    return (
      <WrapperWithLoading
        className={'SelectProductblockPages w ' + (props.className || '')}
        loading={state.loading}
      >
        <div className='rlv'>
          <div className='ov ovxA'>
            <div className='dF aiC jcFS mh-4 (lt|p4)>1'>
              <div>
                <Ripple
                  className='w(|min)115 r12 ov bgF.1 bgF.2:h dn'
                  onClick={handleAdd}
                >
                  <Ratio
                    className='dF aiC jcC bxz aiC jcC ph10'
                    width={115}
                    height={58}
                  >
                    <AddIcon className='f50-i'/>
                  </Ratio>
                </Ripple>
                <div className='mt5 h18'/>
              </div>
              {items.map((page) => {
                return (
                  <SelectProductblockItem
                    key={page.id}
                    data={page}
                    onRemove={handleRemove}
                    onSelect={handleSelect}
                  />
                );
              })}
            </div>
          </div>
          {
            state.opened ? (
              <CelebrationProductblockForm
                className='abs sh-16 sb-16 r10 bg1 ov ovyA z2 hmin600'
                data={productblockData}
                onChange={handleChange}
                onBack={handleBack}
                onSave={handleSave}
              />
            ) : null
          }
        </div>
      </WrapperWithLoading>
    );
  };
});
